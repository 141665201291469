import {ChangeDetectorRef, Component, computed, inject, input, OnDestroy, OnInit, signal} from '@angular/core';
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {RatingCountComponent} from "../../../../../core/components/rating-count/rating-count.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {RestaurantService} from "../../../../restaurants/data/restaurant.service";
import {State} from "../../../../../shared/base/base-state";
import {RestaurantDetailResponse} from "../../../../restaurants/data/restaurant-details/restaurant-detail-response";
import {CartService} from "../../../data/cart.service";
import {DecimalPipe, Location, NgClass} from "@angular/common";
import {CounterInputComponent} from "../../../../../shared/inputs/counter-input/counter-input.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  SingleSelectionFieldComponent
} from "../../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {CommonDataService} from "../../../../../core/services/common-data/common-data.service";
import {TipAmount, WaiterTipsResponse} from "../../../../../core/services/common-data/waiter-tips/waiter-tips-response";
import {NetworkImageComponent} from "../../../../../shared/components/network-image/network-image.component";
import {calculateDistance} from "../../../../../shared/utils/geo-utils";
import {arrayToCSV} from "../../../../../shared/utils/string-utils";
import {AppDataService} from "../../../../../core/services/app-data/app-data.service";
import {TextInputComponent} from "../../../../../shared/inputs/text-input/text-input.component";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {DropdownComponent} from "../../../../../shared/inputs/dropdown/dropdown.component";
import {SavedCardsRequest} from "../../../../payment/data/saved-cards/saved-cards-request";
import {Card, SavedCard, SavedCardsResponse} from "../../../../payment/data/saved-cards/saved-cards-response";
import {PaymentService} from "../../../../payment/data/payment.service";
import {AuthStoreService} from "../../../../../core/services/auth-store-service/auth-store.service";
import {
  PlaceOrderCheck, PlaceOrderCurbside,
  PlaceOrderCustomerInfo,
  PlaceOrderRequest
} from "../../../data/place-order/place-order-request";
import {CartSelectionsToPlaceOrderSelections} from "../../../utilities/cart-selections-to-place-order-selections";
import {MatDialog} from "@angular/material/dialog";
import {
  AddPaymentMethodComponent, AddPaymentMethodStatus
} from "../../../../payment/presentation/overlays/add-payment-method/add-payment-method.component";
import {Constants} from "../../../../../core/constants/constants";
import {PickupOrderService} from "../../../data/pickup-order.service";
import {CreditCardImageUtils} from "../../../../payment/utilites/credit-card-image-utils";
import {
  CreatePaymentIntentForOrderRequest
} from "../../../../payment/data/create-payment-intent-for-order/create-payment-intent-for-order-request";
import {ChipComponent} from "../../../../../core/components/chip/chip.component";
import {StripeService} from "ngx-stripe";
import {
  CreatePaymentIntentForOrderResponse
} from "../../../../payment/data/create-payment-intent-for-order/create-payment-intent-for-order-response";
import {PlaceOrderResponse} from "../../../data/place-order/place-order-response";
import {
  CapturePaymentIntentRequest
} from "../../../../payment/data/capture-payment-intent/capture-payment-intent-request";
import {
  OrderConfirmationOverlayComponent
} from "../../overlay/order-confirmation-overlay/order-confirmation-overlay.component";
import {OrderInformationResponse} from "../../../data/order-information/order-information-response";
import {
  CurbsideVehicleTypesResponse, VehicleType
} from "../../../../../core/services/common-data/curbside-vehicle-types/curbside-vehicle-types-response";
import {VehicleColorsResponse} from "../../../../../core/services/common-data/vehicle-colors/vehicle-colors-response";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input/outline-text-input.component";
import {
  LoginStatus,
  UserLoginOverlayComponent
} from "../../../../auth/presentation/overlays/user-login-overlay/user-login-overlay.component";
import {
  ApplyPromoCodeResponse
} from "../../../../../core/services/common-data/apply-promo-code/apply-promo-code-response";
import {OrderPriceResponse} from "../../../data/order-price/order-price-response";
import {
  ApplyPromoCodeRequest
} from "../../../../../core/services/common-data/apply-promo-code/apply-promo-code-request";
import {
  RemovePromoCodeRequest
} from "../../../../../core/services/common-data/remove-promo-code/remove-promo-code-request";
import {
  RemovePromoCodeResponse
} from "../../../../../core/services/common-data/remove-promo-code/remove-promo-code-response";
import {FoodItem} from "../../../data/menu/menu";
import {CartItem} from "../../../data/cart/cart";

@Component({
  selector: 'app-pickup-checkout',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    RatingCountComponent,
    ChipComponent,
    ButtonComponent,
    DecimalPipe,
    CounterInputComponent,
    FormsModule,
    SingleSelectionFieldComponent,
    NetworkImageComponent,
    ReactiveFormsModule,
    TextInputComponent,
    RadioButtonComponent,
    DropdownComponent,
    NgClass,
    OutlineTextInputComponent
  ],
  templateUrl: './pickup-checkout.component.html',
  styleUrl: './pickup-checkout.component.scss'
})
export class PickupCheckoutComponent extends BaseComponent implements OnInit {
  restaurantId = input<string>('');

  formBuilder = inject(FormBuilder);
  location = inject(Location);
  commonDataService = inject(CommonDataService);
  restaurantService = inject(RestaurantService);
  cartService = inject(CartService);
  appDataService = inject(AppDataService);
  paymentService = inject(PaymentService);
  pickupOrderService = inject(PickupOrderService);
  authStoreService = inject(AuthStoreService);
  dialog = inject(MatDialog);

  restaurantDetailState = new State<RestaurantDetailResponse>();
  waiterTipsState = new State<WaiterTipsResponse>();
  savedCardsState = new State<SavedCardsResponse>();
  curbsideVehicleTypesState = new State<CurbsideVehicleTypesResponse>();
  vehicleColorsState = new State<VehicleColorsResponse>();
  orderPriceState = new State<OrderPriceResponse>();
  placeOrderState = new State<any>();
  applyPromoCodeState = new State<ApplyPromoCodeResponse>();
  removePromoCodeState = new State<RemovePromoCodeResponse>();

  private updatedCheck?: PlaceOrderCheck;

  isPromoCodeApplied = signal(false);


  cards: SavedCardForUI[] = [];

  initialTotalAmount = signal<number>(0);

  protected readonly arrayToCSV = arrayToCSV;
  protected readonly CreditCardImageUtils = CreditCardImageUtils;

  checkoutForm!: FormGroup;

  distanceInMiles = computed<string | null>(() => {
    const restaurant = this.restaurantDetailState.response()?.data;
    let destinationLatitude = restaurant?.location?.location?.coordinates[1];
    let destinationLongitude = restaurant?.location?.location?.coordinates[0];

    let currentLocation = this.appDataService.getAddress();
    let sourceLatitude = currentLocation?.latitude;
    let sourceLongitude = currentLocation?.longitude;
    if (sourceLatitude && sourceLongitude && destinationLatitude && destinationLongitude) {
      const distanceInMiles = calculateDistance(sourceLatitude, sourceLongitude, destinationLatitude, destinationLongitude).miles.toFixed(2);
      return `${distanceInMiles} miles away`
    } else {
      return 'N/A';
    }
  });
  promoCode = signal<string | null>(null);

  ngOnInit(): void {
    this.initCheckoutForm();
    this.getRestaurantDetails();
    this.getWaiterTips();
    this.getCurbsidePickupVehicleTypes();
    this.getVehicleColors();
  }

  initCheckoutForm() {
    this.checkoutForm = this.formBuilder.group({
      waiterTips: [],
      customTipEnabled: [false],
      customTipAmount: [0],
      pickupOption: ['PICKUP'],
      curbsideVehicleType: [],
      curbsideVehicleColor: [],
      differentDriver: [false],
      driverName: [''],
      driverNumber: [''],
      includeUtensils: [false],
      paymentMethod: ['cash'],
      selectedCard: [null],
      specialRequest: []
    });

    this.checkoutForm.controls['pickupOption'].valueChanges.subscribe((value) => {
      switch (value) {
        case 'PICKUP':
          this.checkoutForm.controls['curbsideVehicleType'].setValidators(null);
          this.checkoutForm.controls['curbsideVehicleType'].updateValueAndValidity();

          break;
        case 'CURBSIDE_PICKUP':
          this.checkoutForm.controls['curbsideVehicleType'].setValidators([Validators.required]);
          this.checkoutForm.controls['curbsideVehicleType'].updateValueAndValidity();
          break;
      }
    });

    this.checkoutForm.controls['differentDriver'].valueChanges.subscribe((value) => {
      if (value) {
        this.checkoutForm.controls['driverName'].setValidators([Validators.required]);
        this.checkoutForm.controls['driverNumber'].setValidators([Validators.required]);
      } else {
        this.checkoutForm.controls['driverName'].setValidators(null);
        this.checkoutForm.controls['driverNumber'].setValidators(null);
      }
      this.checkoutForm.controls['driverName'].updateValueAndValidity();
      this.checkoutForm.controls['driverNumber'].updateValueAndValidity();
    });

    this.checkoutForm.controls['paymentMethod'].valueChanges.subscribe((value) => {
      switch (value) {
        case 'cash':
          this.checkoutForm.controls['selectedCard'].setValidators(null);
          break;
        case 'card':
          this.getSavedCards();
          this.checkoutForm.controls['selectedCard'].setValidators([Validators.required]);
          break;
      }
      this.checkoutForm.controls['selectedCard'].updateValueAndValidity();
    });

    this.checkoutForm.controls['customTipAmount'].valueChanges.subscribe((value) => {
      let customTipAmount = this.checkoutForm.controls['customTipAmount'].value;
      if (customTipAmount != null) {
        let tipAmount = Number(customTipAmount);
        this.cartService.cart().setTipAmount(tipAmount);
      }
    });
  }

  getCurbsidePickupVehicleTypes() {
    this.executeRequest({
      state: this.curbsideVehicleTypesState,
      request: this.commonDataService.getCurbsideVehicleTypes(),
    });
  }

  getVehicleColors() {
    this.executeRequest<VehicleColorsResponse>({
      state: this.vehicleColorsState,
      request: this.commonDataService.getVehicleColors(),
    });
  }

  get selectedPickupOption() {
    return this.checkoutForm.controls['pickupOption'].value;
  }


  isPickupSelected() {
    return this.selectedPickupOption === 'PICKUP';
  }

  isCurbsideSelected() {
    return this.selectedPickupOption === 'CURBSIDE_PICKUP';
  }

  onPickupOptionSelected(option: string) {
    this.checkoutForm.controls['pickupOption'].setValue(option);
  }

  getWaiterTips() {
    this.executeRequest({
      state: this.waiterTipsState,
      request: this.commonDataService.getWaiterTips(),
      onSuccess: response => {
        this.checkoutForm.controls['waiterTips'].setValue(response.data[0]);
      }
    });
  }

  getRestaurantDetails() {
    this.executeRequest<RestaurantDetailResponse>({
      state: this.restaurantDetailState,
      request: this.restaurantService.getRestaurantDetails(this.restaurantId()),
      onSuccess: (response) => {
        this.handleOrderUpdate(this.placeOrderRequest(), false);
        this.cartService.cart().setTaxAmount(response?.data?.stateTaxRate, response?.data?.localTaxRate);
      }
    });
  }

  onBackClicked() {
    this.location.back();
  }

  onCustomTipClicked() {
    this.checkoutForm.controls['waiterTips'].setValue(null);
    this.checkoutForm.controls['customTipEnabled'].setValue(true);
  }

  onTipPercentageSelected($event: any) {
    this.checkoutForm.controls['customTipEnabled'].setValue(false);
    this.cartService.cart().setTipPercentage($event.value);
  }

  onRemoveTipClick() {
    let zeroTipAmount = this.waiterTipsState.response()?.data[0];
    this.checkoutForm.controls['waiterTips'].setValue(zeroTipAmount);
    this.checkoutForm.controls['customTipEnabled'].setValue(false);
    this.cartService.cart().setTipPercentage(0);
  }

  onAddNewCardClicked() {
    this.dialog.open(AddPaymentMethodComponent, {
      ...Constants.defaultDialogConfig
    }).afterClosed().subscribe((status: AddPaymentMethodStatus) => {
      if (status == AddPaymentMethodStatus.success) {
        this.getSavedCards();
      }
    });
  }

  onCardSelected(card: SavedCardForUI) {
    this.checkoutForm.controls['selectedCard'].setValue(card);
  }


  onPlaceOrderClicked() {
    if (!this.authStoreService.isAppUserLoggedIn()) {
      this.dialog.open(UserLoginOverlayComponent).afterClosed().subscribe((status: LoginStatus) => {
        if (status == LoginStatus.loginSuccess) {
          this.onPlaceOrderClicked();
        }
      });
      return;
    }
    this.validateForm(this.checkoutForm);
    let restaurantInfo = this.restaurantDetailState.response()?.data;
    let authUser = this.authStoreService.getAppUser()?.data;
    if (this.checkoutForm.invalid || !restaurantInfo || !authUser) {
      return;
    }

    let placeOrderRequest = this.placeOrderRequest();
    this.handleOrderUpdate(placeOrderRequest, true);
  }

  private placeOrderRequest(): PlaceOrderRequest {
    let restaurantInfo = this.restaurantDetailState.response()?.data;
    let authUser = this.authStoreService.getAppUser()?.data;
    let curbside: PlaceOrderCurbside = {};
    let formValue = this.checkoutForm.value;
    let diningOption = 'PICK_UP';
    if (this.isCurbsideSelected()) {
      let driverName: string = '';
      let driverPhone: string | undefined;
      if (formValue.differentDriver) {
        driverName = formValue.driverName;
        driverPhone = formValue.driverNumber;
      } else {
        let user = this.authStoreService.getAppUser()?.data;
        driverName = `${user?.firstName} ${user?.lastName}`;
        // TODO: What if the user phone number is not available
        driverPhone = user?.phoneNumber ?? user?.email ?? '';
      }
      diningOption = 'CURBSIDE_PICK_UP';
      curbside = {
        curbsideOption: true,
        driverName: driverName,
        driverPhone: driverPhone,
        driverSameAsCustomer: !formValue.differentDriver,
        vehicleType: formValue.curbsideVehicleType.name,
        vehicleColor: formValue.curbsideVehicleColor
      };
    }

    let customerInfo: PlaceOrderCustomerInfo = {
      firstName: authUser?.firstName,
      lastName: authUser?.lastName,
      phoneNumber: authUser?.phoneNumber,
      email: authUser?.email
    };
    let check: PlaceOrderCheck = {
      companyUID: restaurantInfo?.companyUID,
      amount: 0,
      checkNumber: undefined,
      customerInfo: customerInfo,
      netAmount: 0,
      restaurant: restaurantInfo?._id,
      selections: CartSelectionsToPlaceOrderSelections.convert(this.cartService.cart().selections),
      taxAmount: 0,
      tipAmount: this.cartService.cart().tipAmount,
      totalAmount: 0,
      totalDiscountAmount: 0
    };
    let fulfillmentDate = new Date(this.cartService.pickupTime ?? '')
    let promisedDate = new Date(fulfillmentDate.getTime() + (30 * 60 * 1000));
    if (this.updatedCheck != null) {
      check = this.updatedCheck;
    }
    let placeOrderRequest: PlaceOrderRequest = {
      companyUID: restaurantInfo?.companyUID,
      restaurant: restaurantInfo?._id,
      diningOption: diningOption, // PICK_UP, 'CURBSIDE_PICK_UP'
      orderScheduleOption: this.cartService.pickupOption, // ASAP, LATER
      orderSource: 'GUEST_APP_WEB',
      orderNote: this.checkoutForm.controls['specialRequest'].value,
      requiredPrepTime: 30,
      duration: 10,
      customerInfo: customerInfo,
      curbside: curbside,
      fulfillmentDate: fulfillmentDate,
      promisedDate: promisedDate,
      checks: [check]
    };

    return placeOrderRequest;
  }

  private getSavedCards() {
    if (!this.authStoreService.isAppUserLoggedIn()) {
      return;
    }
    let request: SavedCardsRequest = {
      userId: this.authStoreService.getAppUser()?.data?.user
    };
    this.executeRequest<SavedCardsResponse>({
      state: this.savedCardsState,
      request: this.paymentService.getSavedCards(request),
      onSuccess: (response) => {
        this.cards = [];
        for (const savedCard of response.data ?? []) {
          this.cards.push({
            card: savedCard,
            cardInfo: `XXXX - XXXX - XXXX - ${savedCard.card.last4} - ${savedCard.card.brand}`
          });
        }
      }
    })
  }

  private handleOrderUpdate(placeOrderRequest: PlaceOrderRequest, placeOrder: boolean) {
    this.executeRequest({
      state: this.orderPriceState,
      request: this.pickupOrderService.getOrderPrice(placeOrderRequest),
      handleError: true,
      handleSuccess: false,
      showLoader: true,
      onSuccess: response => {
        this.initialTotalAmount.set(response.data.orderTotalAmount);
        if (placeOrder) {
          this.placeOrder(response.data);
        }
      }
    });
  }

  private placeOrder(data: any) {
    this.executeRequest<PlaceOrderResponse>({
      state: this.placeOrderState,
      request: this.pickupOrderService.placeOrder(data),
      handleSuccess: false,
      handleError: true,
      showLoader: true,
      onSuccess: response => {
        let paymentMethod = this.checkoutForm.controls['paymentMethod'].value;
        if (paymentMethod == 'cash') {
          this.showOrderInfoOverlay(response.data.orderId);
          this.cartService.resetCart();
        } else if (paymentMethod == 'card') {
          this.handlePayment(response);
        }
      }
    });
  }

  private handlePayment(placeOrderResponse: PlaceOrderResponse) {
    let selectedCard: SavedCardForUI = this.checkoutForm.controls['selectedCard'].value;
    let request: CreatePaymentIntentForOrderRequest = {
      orderId: placeOrderResponse.data.orderId,
      checkId: placeOrderResponse.data.checkId,
      paymentMethodId: selectedCard.card.id,
      userPaymentId: selectedCard.card.customer
    };
    this.executeRequest<CreatePaymentIntentForOrderResponse>({
      state: new State(),
      request: this.paymentService.createPaymentIntentForOrder(request),
      handleSuccess: true,
      handleError: true,
      showLoader: true,
      onSuccess: createPaymentIntentForOrderResponse => {
        // TODO: Enable this workflow
        // this.initStripeConfirmPayment(placeOrderResponse, createPaymentIntentForOrderResponse);
        this.cartService.resetCart();
        this.showOrderInfoOverlay(placeOrderResponse.data.orderId);
      }
    });
  }

  private initStripeConfirmPayment(placeOrderResponse: PlaceOrderResponse, createPaymentIntentForOrderResponse: CreatePaymentIntentForOrderResponse) {
    let request: CapturePaymentIntentRequest = {
      paymentIntentId: createPaymentIntentForOrderResponse.paymentIntentId,
      orderId: placeOrderResponse.data.orderId,
      checkId: placeOrderResponse.data.checkId
    };

    this.executeRequest({
      state: new State(),
      request: this.paymentService.capturePaymentIntent(request),
      showLoader: true,
      handleError: true,
      onSuccess: (response) => {
        console.log(response);
      }
    });
  }

  private showOrderInfoOverlay(orderId: string) {
    this.executeRequest<OrderInformationResponse>({
      state: new State(),
      request: this.pickupOrderService.getOrderDetails(orderId),
      onSuccess: (response) => {
        this.router.navigate([`/pickup-restaurant-details/${this.restaurantId()}`]);
        this.dialog.open(OrderConfirmationOverlayComponent,
          {data: response.data});
      }
    });
  }

  onVehicleTypeClicked(type: VehicleType) {
    this.checkoutForm.controls['curbsideVehicleType'].setValue(type);
  }

  onLoginPressed() {
    this.dialog.open(UserLoginOverlayComponent).afterClosed().subscribe((status: LoginStatus) => {
      if (status == LoginStatus.loginSuccess) {
        this.getSavedCards();
      }
    });
  }

  onApplyPromoCodeClicked() {
    this.applyPromoCode();
  }

  private applyPromoCode() {
    if (this.promoCode() == null || this.promoCode() == '') {
      return;
    }

    let orderRequest = this.placeOrderRequest();

    let request: ApplyPromoCodeRequest = {
      promoCodeNumber: this.promoCode() ?? '',
      check: orderRequest.checks[0],
    };

    this.executeRequest({
      state: this.applyPromoCodeState,
      request: this.commonDataService.applyPromoCode(request),
      onSuccess: response => {
        const data: PlaceOrderCheck = response.data;
        this.updatedCheck = data;
        this.isPromoCodeApplied.update(value => true);
        if (data.totalAmount) {
          this.cartService.cart().setDiscountAmount(response.data.totalDiscountAmount);
          this.isPromoCodeApplied.set(true);
        }
      }
    });
  }

  onRemovePromoCodeClicked() {
    let currentCheck: PlaceOrderCheck = this.placeOrderRequest().checks[0];
    let request: RemovePromoCodeRequest = {
      check: currentCheck,
    };
    this.executeRequest({
      state: this.removePromoCodeState,
      request: this.commonDataService.removePromoCode(request),
      onSuccess: response => {
        this.isPromoCodeApplied.set(false);
        this.promoCode.set(null);
        const data: PlaceOrderCheck = response.data;
        this.updatedCheck = data;
        if (data.totalAmount) {
          this.cartService.cart().setDiscountAmount(response.data.totalDiscountAmount);
        }
      }
    });
  }

  onItemQuantityChange($event: number, selection: CartItem) {
    selection.quantity.set($event);
    if (this.applyPromoCodeState.response()?.data) {
      this.applyPromoCode();
    }
  }
}


export interface SavedCardForUI {
  card: SavedCard;
  cardInfo: string;
}
