<div class="container mx-auto relative grid grid-cols-1 sm:grid-cols-2 py-5 px-4 gap-4">
  <form [formGroup]="checkoutForm" class="flex flex-col gap-4">
    <div class="inline-flex w-min items-center gap-4 rounded-full cursor-pointer">
      <app-svg-icon (click)="onBackClicked()" [size]="24" src="assets/icons/left-arrow.svg"
                    class="text-neutral-600 bg-neutral-100 p-3 rounded-full"></app-svg-icon>
      <h1 class="text-h6 text-nowrap">Secure Checkout</h1>
    </div>
    <div class="flex gap-3 py-5">
      <div class="h-32 rounded-md overflow-hidden">
        <app-network-image [imageUrl]="restaurantDetailState.response()?.data?.banner?.url"></app-network-image>
      </div>
      <div class="flex flex-col gap-3">
        <div class="flex items-center gap-2">
          <p class="text-neutral-400 text-body2">{{ restaurantDetailState.response()?.data?.restaurantName }}</p>
          <app-rating-count [rating]="restaurantDetailState.response()?.data?.rating ?? 0"
                            [count]="restaurantDetailState.response()?.data?.ratingCount ?? 0"></app-rating-count>
        </div>
        <div class="flex gap-2">
          @for (restaurant of restaurantDetailState.response()?.data?.cuisines; track restaurant) {
            <app-chip [name]="restaurant.name"></app-chip>
          }
        </div>
        <div class="flex gap-3 items-center">
          <app-svg-icon src="assets/icons/miles-away.svg" class="text-neutral-500"></app-svg-icon>
          <p class="text-body2 text-sky-400">{{ distanceInMiles() }}</p>
        </div>
        <div class="flex gap-3 items-center">
          <app-svg-icon src="assets/icons/location.svg" class="text-neutral-500"></app-svg-icon>
          <p class="text-neutral-400 text-body2">
            {{ arrayToCSV([restaurantDetailState.response()?.data?.location?.address1, restaurantDetailState.response()?.data?.location?.city, restaurantDetailState.response()?.data?.location?.state]) }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-3">
      <app-single-selection-field
        formControlName="waiterTips"
        title="Waiter Tip"
        display="displayName"
        customActionText="Custom Tip"
        [items]="waiterTipsState.response()?.data ?? []"
        (onCustomActionClicked)="onCustomTipClicked()"
        (valueChanged)="onTipPercentageSelected($event)">
      </app-single-selection-field>
      @if (checkoutForm.controls['customTipEnabled'].value) {
        <app-text-input formControlName="customTipAmount" label="Custom Tip Amount (in USD)"
                        type="number"></app-text-input>
      }
    </div>


    <div class="flex items-center justify-between rounded-md px-3 py-4 shadow-1 border border-neutral-200">
      <div class="flex gap-4">
        <p class="text-body2">Tip Amount</p>
        <p class="text-body2 text-primary-500">$ {{ cartService.cart().tipAmount | number:'1.2-2' }}</p>
      </div>
      <div class="flex gap-3 items-center cursor-pointer" (click)="onRemoveTipClick()">
        <app-svg-icon src="assets/icons/close.svg" class="text-red-500" [size]="10"></app-svg-icon>
        <p class="text-body2 text-red-500">Remove Tip</p>
      </div>
    </div>

    <div class="flex flex-col gap-3">
      <p class="text-body2">Pickup Options</p>
      <div class="bg-slate-100 rounded-md px-4 py-4">

        <div class="flex gap-4 items-center my-5">

          <div (click)="onPickupOptionSelected('PICKUP')"
               class="relative flex gap-5 items-center px-3 py-1 border-primary-500 rounded-md cursor-pointer"
               [ngClass]="isPickupSelected() ? 'border-2' : 'border'">
            @if (isPickupSelected()) {
              <div class="flex justify-center items-center absolute -top-2 -right-1 w-3 h-3 rounded-full">
                <app-svg-icon src="assets/icons/check-circle.svg"
                              class=" text-primary-500 rounded-full bg-white"></app-svg-icon>
              </div>
            }
            <app-svg-icon src="assets/icons/checkout/pickup.svg" class="w-4 text-primary-500"></app-svg-icon>
            <p class="text-body2">Pickup</p>
          </div>

          <div (click)="onPickupOptionSelected('CURBSIDE_PICKUP')"
               class="relative flex gap-5 items-center px-3 py-1 border border-primary-500 rounded-md cursor-pointer"
               [ngClass]="isCurbsideSelected() ? 'border-2' : 'border'">
            @if (isCurbsideSelected()) {
              <div class="flex justify-center items-center absolute -top-2 -right-1 w-3 h-3 rounded-full">
                <app-svg-icon src="assets/icons/check-circle.svg"
                              class=" text-primary-500 rounded-full bg-white"></app-svg-icon>
              </div>
            }
            <app-svg-icon src="assets/icons/checkout/curbside.svg" class="w-4 text-primary-500"></app-svg-icon>
            <p class="text-body2">Curbside pickup</p>
          </div>

        </div>

        <div class="flex flex-col gap-3">
          @if (isCurbsideSelected()) {
            <div class="flex flex-col gap-3 justify-center">
              <p class="text-body2">Vehicle Type <span class="text-red-500">*</span></p>
              <div class="flex gap-3">
                @for (type of curbsideVehicleTypesState.response()?.data ?? []; track type) {
                  <div (click)="onVehicleTypeClicked(type)"
                       class="flex flex-col gap-2 items-center justify-center w-min bg-white p-4 rounded-md cursor-pointer"
                       [ngClass]="checkoutForm.controls['curbsideVehicleType'].value == type ? 'border-2 border-primary-500' : ''">
                    <app-svg-icon [src]="type.icon" class="text-primary-500" [size]="34"></app-svg-icon>
                    <p class="text-caption">{{ type.displayName }}</p>
                  </div>
                }
                @if (checkoutForm.controls['curbsideVehicleType'].hasError('required')) {
                  <p class="text-caption text-error">Required</p>
                }
              </div>
            </div>

            <div class="flex flex-col gap-3">
              <p class="text-body2">Vehicle Color</p>
              <div class="flex gap-3">
                <app-single-selection-field
                  formControlName="curbsideVehicleColor"
                  [items]="vehicleColorsState.response()?.data ?? []"
                  display="displayName"
                  value="displayName"></app-single-selection-field>
              </div>
            </div>

            <div class="flex flex-col gap-3">
              <p class="text-body2">Is someone else picking up the order?</p>
              <div class="flex gap-3">
                <app-radio-button formControlName="differentDriver" groupName="differentDriver" [value]="true"
                                  title="Yes"></app-radio-button>
                <app-radio-button formControlName="differentDriver" groupName="differentDriver" [value]="false"
                                  title="No"></app-radio-button>
              </div>
              @if (checkoutForm.controls['differentDriver'].value == true) {
                <div class="flex gap-3 w-full">
                  <app-outline-text-input class="w-full" [fullWidth]="true" formControlName="driverName"
                                          label="Contact Name"></app-outline-text-input>
                  <app-outline-text-input class="w-full" [fullWidth]="true" formControlName="driverNumber"
                                          label="Contact Number"></app-outline-text-input>
                </div>
              }
            </div>
          }
        </div>

      </div>
    </div>


    <div class="flex flex-col gap-3">
      <p class="text-body2">Any special request?</p>
      <app-text-input formControlName="specialRequest" label="Special Request"></app-text-input>
    </div>


    <div class="flex flex-col gap-3">
      <p class="text-body2"> Include Silverware (Forks, Knives, Spoons and Napkins)</p>
      <div class="flex items-center gap-5">
        <app-radio-button formControlName="includeUtensils" groupName="include-utensils" [value]="false"
                          title="No"></app-radio-button>
        <app-radio-button formControlName="includeUtensils" groupName="include-utensils" [value]="true"
                          title="Yes"></app-radio-button>
      </div>
    </div>


    <div class="flex flex-col gap-3">
      <p class="text-primary-500 text-body2">Payment Options</p>

      <div class="flex gap-4">
        <div class="flex items-center justify-center px-4 py-5 shadow-1 rounded-md whitespace-nowrap">
          <app-radio-button formControlName="paymentMethod" groupName="paymentMethod" title="Pay as Cash"
                            value="cash"></app-radio-button>
        </div>
        <div class="flex items-center justify-center px-4 py-3 shadow-1 rounded-md whitespace-nowrap">
          <app-radio-button formControlName="paymentMethod" groupName="paymentMethod" title="Credit / Debit Card"
                            value="card"></app-radio-button>
        </div>
      </div>

      @if (checkoutForm.controls['paymentMethod'].value == 'card') {
        @if (this.authStoreService.isAppUserLoggedIn()) {
          <div class="flex flex-col gap-3 w-full">
            <p class="text-body2 py-3">Saved Cards</p>

            <app-button [fullWidth]="true" appearance="outline" (click)="onAddNewCardClicked()">+ Add New Card
            </app-button>

            @for (card of cards; track card) {
              <div
                class="flex items-center gap-3 px-5 py-3 bg-white shadow-1 rounded-md cursor-pointer"
                [ngClass]="card == checkoutForm.controls['selectedCard'].value ? 'border-2 border-primary-500' : 'border border-neutral-100'"
                (click)="onCardSelected(card)">
                <app-svg-icon [src]="CreditCardImageUtils.getCardImage(card.card.card.brand)"></app-svg-icon>
                <p class="text-body2">XXXX- XXXX - XXXX - {{ card.card.card.last4 }}</p>
              </div>
            }
            <div class="flex items-start gap-3 w-full">
            </div>
          </div>
        } @else {
          <app-button (click)="onLoginPressed()">Login</app-button>
        }
      }
    </div>


    <app-button [fullWidth]="true" [loading]="placeOrderState.loading()" (click)="onPlaceOrderClicked()">PLACE ORDER
    </app-button>
  </form>

  <div>
    <div class="sticky top-20 flex flex-col border px-4 py-4 rounded-md w-full shadow-1 gap-4">
      <p class="text-body2 font-medium">Your Order</p>
      <p class="border-b"></p>
      <div class="flex flex-col gap-3">

        @for (selection of cartService.cart().selections; track selection) {
          <div class="flex justify-between">
            <div class="flex flex-col gap-2">
              <p class="text-body2">{{ selection.menuItem.name }}</p>
              <div class="flex flex-wrap gap-3">
                @for (mg of selection.menuItem.modifierGroups; track mg; let isLast = $last) {
                  @for (modifier of mg.modifiers; track modifier) {
                    @if (modifier.selectedQuantity() > 0) {
                      <div class="flex">
                        <span class="text-body2 text-neutral-500">{{ modifier.name }}
                          x {{ modifier.selectedQuantity() }}</span>
                        @if (!isLast) {
                          <span class="text-body2 text-neutral-500 mr-2">,</span>
                        }
                      </div>
                    }
                  }
                }
              </div>
            </div>
            <div class="flex items-center gap-4">
              <p class="text-body2 text-primary-900">${{ selection.getSubtotal() | number:'1.2-2' }}</p>
              <app-counter-input [minValue]="0" appearance="flat" [ngModel]="selection.quantity()" (valueChanged)="onItemQuantityChange($event, selection)"></app-counter-input>
            </div>
          </div>
        }
      </div>

<!--      <div class="flex flex-col gap-1">-->
<!--        <div class="flex gap-2">-->
<!--          <app-svg-icon src="assets/icons/promo-code.svg" class="text-neutral-500"></app-svg-icon>-->
<!--          <p class="text-body2 text-neutral-300">Apply Promo Code</p>-->
<!--        </div>-->
<!--        <div class="flex gap-4 items-center">-->
<!--          <app-outline-text-input class="grow"-->
<!--                                  [(ngModel)]="promoCode"-->
<!--                                  (ngModelChange)="promoCode.set($event)"-->
<!--                                  [fullWidth]="true"></app-outline-text-input>-->
<!--          @if (isPromoCodeApplied()) {-->
<!--            <app-button (click)="onRemovePromoCodeClicked()">REMOVE</app-button>-->
<!--          } @else {-->
<!--            <app-button (click)="onApplyPromoCodeClicked()">APPLY</app-button>-->
<!--          }-->
<!--        </div>-->
<!--      </div>-->

      <div class="flex flex-col gap-3">
        <div class="flex justify-between">
          <p class="text-body2 text-neutral-500">Subtotal</p>
          <p class="text-body2 text-neutral-900">$ {{ cartService.cart().getSubtotal | number:'1.2-2' }}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-body2 text-neutral-500">Tax</p>
          <p class="text-body2 text-neutral-900">$ {{ cartService.cart().taxAmount | number: '1.2-2' }}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-body2 text-neutral-500">Waiter Tip</p>
          <p class="text-body2 text-neutral-900">$ {{ cartService.cart().tipAmount | number:'1.2-2' }}</p>
        </div>
        <div class="flex justify-between">
          <p class="text-body2 text-neutral-500">Discount</p>
          <p class="text-body2 text-red-500">
            <span
              class="text-body2 text-red-500">- $</span> {{ cartService.cart().discountAmount | number:'1.2-2' }}
          </p>
        </div>
        <div class="flex justify-between font-bold text-body2 text-neutral-900">
          <p>Total</p>
          <p>$ {{ cartService.cart().totalAmount | number:'1.2-2' }}</p>
        </div>
      </div>


    </div>
  </div>
</div>
